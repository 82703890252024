import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Company } from '../../_models/company.model';

@Injectable({
  providedIn: 'root',
})
export class InitService {
  private apiUrl = environment.urlApi;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient
  ) { }

  getExternalReferences(): Observable<Company> {
    const usernameNumber = this.getUsernameNumber();
    const origin = 'scm';

    if (!usernameNumber) {
      return throwError(() => new Error('No se pudo extraer el número del username del token.'));
    }

    const url = `${this.apiUrl}/Companies/externalreferences/${origin}/${usernameNumber}`;

    return this.http.get<Company>(url)
      .pipe(
        tap((response) => {
          if (response && response._id) {
            localStorage.setItem('companyId', response._id);
          } else {
            console.error('No se encontró el campo companyId en la respuesta.');
          }
        }),
        catchError(this.handleError)
      )
  }

  getUsernameNumber(): string | null {
    const token = this.getAccessToken();

    if (!token) {
      console.error('No se encontró el token "accessToken" en las cookies.');
      return null;
    }

    try {
      const tokenParts = token.split('.');
      if (tokenParts.length !== 3) {
        console.error('El token JWT no tiene un formato válido.');
        return null;
      }

      const payload = JSON.parse(atob(tokenParts[1]));

      if (payload && payload.username) {
        const match = payload.username.match(/_(\d+)$/);
        return match ? match[1] : null;
      } else {
        console.error('El token no contiene un campo "username".');
        return null;
      }
    } catch (error) {
      console.error('Error al decodificar el token:', error);
      return null;
    }
  }

  getAccessToken(): string | null {
    var accessToken = this.cookieService.get('accessToken') || null;
    if (!accessToken)
      accessToken = 'eyJraWQiOiJ2R0ljVFdLS01oem4yT2tPMjVZeHcwZER6ajZ3U2Mya0VUYUIxaEhRcmVNPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI0NWQ1YWQ2YS1iZWVkLTQ1YWQtODBmZS1lNGQzZjI5MmI5OWMiLCJldmVudF9pZCI6ImQwMzUzYmNhLWM0NjgtNGI5NC1iNDg0LTJjMDc1NmMxYzhjNCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MzAxNDYyNTAsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xXzNicWxTUHZGSiIsImV4cCI6MTczMDE0OTg0OSwiaWF0IjoxNzMwMTQ2MjUwLCJqdGkiOiIzMGFjOTI2NC0yMDEwLTQ0ZTUtYWYwNS1hOGE3OGM4OTI3ZTYiLCJjbGllbnRfaWQiOiIyNGRpZTJzcmpyMHM5Z2h1bDUzZWFoMTNiMyIsInVzZXJuYW1lIjoiYWRtaW5fMyJ9.lF9JWBdt8tAtn_Aag--T-JhLfc2CdxWK6XSFtZM6zzc1ODENSAm7tYgI1idGCAMVqqlVQLcypldlFfrbU25mN_8v6RmrMFx9A_OmEtbft1PxQBO031BZ2PyJdPZndJh7cHiQyRIW9KL5GonmyDqV_JbrDbQs3EkMw8xH3bAfjesake_277McPEgLQe0D2gu3Oi4BhXivZqRcYazLe8_r_udumU1qHwjj_6p4nWcMfE7JNZB9zkskiWFS6WMvmUa4-5fvXuRYeh_fUvkjsHnuMfWH7NQ4oIPpWbM81O4rnVM94560yU38jkTapY-c3KCvS91Qev_6UClGk63XmfMp_A';

    return accessToken;
  }

  getCompanyIdLocalStorage(): string {
    return localStorage.getItem('companyId') || "";
  }

  private handleError(error: any): Observable<never> {
    console.error('Error en la solicitud HTTP:', error);
    return throwError(() => new Error('Ocurrió un error, por favor inténtelo de nuevo más tarde.'));
  }
}
